const serverUrl = process.env.SERVER_URL;
const sdkKey = process.env.ZOOM_SDK_KEY;
const leaveUrl = process.env.HOST_LEAVE_URL;
const userName = process.env.USER_NAME;
const meetingNumber = process.env.MEETING_NUMBER;
const keepImages = process.env.KEEP_IMAGES;
const showLogs = process.env.SHOW_LOGS;
const showErLogs = process.env.SHOW_ER_LOGS;
const isHostOn = process.env.IS_HOST_KPI_ON;

export const defaultConfig = {
    serverUrl,
    sdkKey,
    leaveUrl,
    userName,
    meetingNumber,
    keepImages: keepImages === 'true',
    showLogs: showLogs === 'true',
    showErLogs: showErLogs === 'true',
    isHostOn: isHostOn === 'true'
};
